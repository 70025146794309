import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { Page404Component } from './views/pages/page404/page404.component';
import { Page500Component } from './views/pages/page500/page500.component';
import { LoginComponent } from './views/pages/login/login.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { authGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'campaigns/emails/blocks',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./views/email-blocks/email-blocks.module').then((m) => m.EmailBlocksModule)
      },
      {
        path: 'campaigns/emails',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./views/campaign-emails/campaign-emails.module').then((m) => m.CampaignEmailsModule)
      },
      {
        path: 'campaigns',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./views/campaigns/campaigns.module').then((m) => m.CampaignsModule)
      },
      {
        path: 'layouts',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./views/layouts/layouts.module').then((m) => m.LayoutsModule)
      },
      {
        path: 'blocks',
        canActivate: [authGuard],
        loadChildren: () =>
          import('./views/blocks/blocks.module').then((m) => m.BlocksModule)
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/pages.module').then((m) => m.PagesModule)
      },
    ]
  },
  {
    path: '404',
    component: Page404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: Page500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';
import { ClientService } from '../../shared/services/clients.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, map, of, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs';
import { ApiHandlerService } from 'source/app/shared/services/api-handler.service';
import { Block,ListBlock } from 'src/app/shared/models/blocks';
import { SharedItemsService } from 'source/app/shared/services/shared-items.service';

@Injectable({
  providedIn: 'root'
})

export class BlockService {
  private blocksChangedSubject = new BehaviorSubject<void>(undefined);
  blocksChanged$ = this.blocksChangedSubject.asObservable();

  blocksList$!: Observable<ListBlock[]>;
  private selectedListBlockSubject = new BehaviorSubject<ListBlock>({} as ListBlock);
  public selectedListBlock$ = this.selectedListBlockSubject.asObservable();
  public selectedBlock$:Observable<Block> = this.selectedListBlock$.pipe(
    switchMap((block: ListBlock) => {
      const blockId = block?.blockID ?? 0;
      return blockId > 0 ? this.getBlock(blockId) : of({} as Block);
    }),
    shareReplay(1)
  );
  isAddMode = false;
  globalBlockId: number = 0;
  private onDestroy$ = new Subject<void>();

  constructor(
    private clientService: ClientService,
    private api: ApiHandlerService,
  ) {
    this.blocksList$ = combineLatest([
      this.clientService.selectedClient$,
      this.blocksChangedSubject
    ]).pipe(
      switchMap(([client]) => {
        const clientId = client?.clientID ?? 0;
        return this.getAllBlocks(clientId);
      }),
      shareReplay(1)
    );
    this.blocksChangedSubject.subscribe(() => {
      this.selectedListBlockSubject.next({} as ListBlock);
    });
  }

  // Get all blocks. Use BlockList list schema to call /block/getall.
  getAllBlocks(clientId: number): Observable<ListBlock[]> {
    if (clientId === 0) {
      return of([]);
    }
    return this.api.GetAll(ApiHandlerService.urls.blockGetAll, { clientId }).pipe(
      map((response: any) => {
        if (response.isSuccess) {
          const blocks = response.blocks as ListBlock[] ?? [];
          this.globalBlockId = blocks.find(b => b.type === '_global')?.blockID ?? 0;
          return blocks;
        } else {
          this.globalBlockId = 0;
          return [];
        }
      }),
      shareReplay(1)
    );
  }

  setBlock(block: ListBlock) {
    this.selectedListBlockSubject.next(block);
  }
  // setBlock(id: number) {
  //   this.selectedBlockIDSubject.next(id);
  // }

  getBlock(blockId: number): Observable<Block>{
    if(blockId === 0){
      return of({} as Block);
    }
    return this.api.Get(ApiHandlerService.urls.blockGet, { blockId }).pipe(
      map((response:any) => response.isSuccess ? response : null),
      shareReplay(1)
    );
  }

  addBlock(block: Block) {
    block.name ??= block.type;
    block.description ??= "";
    block.html ??= "";
    block.blockData ??= '{}';
    block.schema ??= '{}';
    block.uI_Schema ??= '{}';
    return this.api.Post(ApiHandlerService.urls.blockAdd, block).pipe(
      map((response:any) => {
        if (response.isSuccess) {
          this.blocksChangedSubject.next();
          return response.blockID;
        }
      }),
      shareReplay(1)
    );
  }

  updateBlock(block: Block) {
    if(block.blockID === 0){
      throw new Error('Invalid block ID');
    }
    return this.api.Put(ApiHandlerService.urls.blockUpdate, block).pipe(
      map((response:any) => {
        if((response as any).isSuccess){
          this.blocksChangedSubject.next();
          return true;
        }else{
          throw new Error((response as any).errorOnFailure);
        }
      }),
      shareReplay(1)
    );
  }

  copyBlock(blockID: number, clientIDToCopy: number, newType: string) {
    if(blockID === 0){
      throw new Error('Invalid block ID');
    }
    return this.api.Post(ApiHandlerService.urls.blockCopy, { blockID, clientIDToCopy, newType}).pipe(
      map((response:any) => {
        if(response.isSuccess){
          this.blocksChangedSubject.next();
          return true;
        }else{
          throw new Error(response.errorOnFailure);
        }
      }),
      shareReplay(1)
    );
  }

  moveBlock(blockID: number, clientIDToMove: number) {
    if(blockID === 0){
      throw new Error('Invalid block ID');
    }
    return this.api.Post(ApiHandlerService.urls.blockMove, { blockID, clientIDToMove }).pipe(
      map((response:any) => {
        if(response.isSuccess){
          this.blocksChangedSubject.next();
          return true;
        }else{
          throw new Error(response.errorOnFailure);
        }
      }),
      shareReplay(1)
    );
  }

  deleteBlock(blockID: number) {
    if(blockID === 0){
      throw new Error('Invalid block ID');
    }
    return this.api.Delete(ApiHandlerService.urls.blockDelete, { blockID }).pipe(
      map((response:any) => {
        if(response.isSuccess){
          this.blocksChangedSubject.next();
          return true;
        }else{
          throw new Error(response.errorOnFailure);
        }
      }),
      shareReplay(1)
    );
  }

  updateSampleImage(blockID: number, sampleImageAsBase64String: string, originalImageName: string): Observable<boolean> {
    if (blockID === 0) {
      throw new Error('Invalid block ID');
    }
    return this.api.Put(ApiHandlerService.urls.blockUpdateSampleImage, { blockID, sampleImageAsBase64String, originalImageName }).pipe(
      map((response: any) => {
        if (response.isSuccess) {
          this.blocksChangedSubject.next();
          return true;
        } else {
          throw new Error(response.errorOnFailure);
        }
      }),
      shareReplay(1)
    );
  }

  convertHtmlToTemplate(blockID: number, additionalPrompt: string, inputData: string, apiKey: string): Observable<any> {
    if (blockID === 0) {
      throw new Error('Invalid block ID');
    }
    let url = "";
    if (apiKey && apiKey?.trim() != ""){
      url = ApiHandlerService.urls.convertHtmlToTemplate + "?apiKey="+ apiKey;
    } else {
      url = ApiHandlerService.urls.convertHtmlToTemplate;
    }
    if (!additionalPrompt){
      additionalPrompt = "";
    }
    return this.api.Post(url, { blockID, additionalPrompt, inputData }).pipe(
      map((response: any) => {
        return response;
      }),
      shareReplay(1)
    );
  }

  generateSchema(blockID: number, additionalPrompt: string, inputData: string, apiKey: string): Observable<any> {
    if (blockID === 0) {
      throw new Error('Invalid block ID');
    }
    let url = "";
    if (apiKey && apiKey?.trim() != ""){
      url = ApiHandlerService.urls.generateSchemaWithAI + "?apiKey="+ apiKey;
    } else {
      url = ApiHandlerService.urls.generateSchemaWithAI;
    }
    if (!additionalPrompt){
      additionalPrompt = "";
    }
    return this.api.Post(url, { blockID, additionalPrompt, inputData }).pipe(
      map((response: any) => {
        return response;
      }),
      shareReplay(1)
    );
  }

  validateSchema(blockData: any, schema: any): string[] {
    const missingSchemaFields: string[] = [];
    const schemaProperties = Object.keys(schema.properties);
    for (const key in blockData) {
      if (!schemaProperties.includes(key)) {
        missingSchemaFields.push(key);
      }
      if (typeof blockData[key] === 'object' && !Array.isArray(blockData[key])) {
        const nestedSchema = schema.properties[key];
        missingSchemaFields.push(...this.validateSchema(blockData[key], nestedSchema));
      }
      if (Array.isArray(blockData[key])) {
        const arraySchema = schema.properties[key].items;
        for (const item of blockData[key]) {
          missingSchemaFields.push(...this.validateSchema(item, arraySchema));
        }
      }
    }
    return missingSchemaFields;
  }

  validateUISchema(blockData: any, uiSchema: any, schema: any): string[] {
    const missingUISchemaFields: string[] = [];
    const topLevelElement = uiSchema.elements[0];
    if (topLevelElement.scope === '#/') {
      for (const key in blockData) {
        if (!schema.properties[key]) {
          missingUISchemaFields.push(key);
        }
      }
    } else {
      const scopeProperty = topLevelElement.scope.substring(13); // Remove leading "#/properties/"
      for (const element of uiSchema.elements) {
        if (element.scope.startsWith(`#/properties/${scopeProperty}/`)) {
          const subSchemaPath = element.scope.substring(29 + scopeProperty.length); // Remove leading "#/properties/<property>/"
          if (!schema.properties[scopeProperty] || !schema.properties[scopeProperty].properties) {
            continue; // Skip if sub-schema doesn't exist
          }
          const subSchema = schema.properties[scopeProperty].properties;
          missingUISchemaFields.push(...this.validateUISchema(blockData[scopeProperty], {}, subSchema).map(field => `${scopeProperty}.${field}`));
        }
      }
    }
    return missingUISchemaFields;
  }

}

import { Component } from '@angular/core';
import { FooterComponent } from '@coreui/angular';
import { environment } from 'source/environments/environment';

@Component({
  selector: 'app-default-footer',
  templateUrl: './default-footer.component.html',
  styleUrls: ['./default-footer.component.scss'],
})
export class DefaultFooterComponent extends FooterComponent {  
  public commitHash = environment.commit_hash.includes('~') ? 'development' : environment.commit_hash;
  constructor() {
    super();
  }
}

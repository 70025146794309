import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ModalModule,
  ButtonModule,
} from '@coreui/angular';


@Component({
  selector: 'app-confirmation-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule,CommonModule],
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss'
})
export class ConfirmationModalComponent {
  @Input() message = "";
  @Input() title = "Confirm Action";
  @Input() showbg  = false;
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  visible = false;

  open() {
    this.visible = true;
  }

  close() {
    this.visible = false;
  }

  confirmed() {
    this.confirm.emit();
    this.close();
  }

  cancelled() {
    this.cancel.emit();
    this.close();
  }
}

<form cForm [formGroup]="blockMoveForm" validated="false">
  <div class="mb-3">
    <label cLabel for="clientID">Select client where block will be moved to</label>
    <select cSelect id="clientID" formControlName="ClientID"
      [valid]="blockMoveForm.controls['ClientID'].valid">
      <option *ngFor="let client of filteredClients" [value]="client.clientID">
        {{client.clientName}}
      </option>
    </select>
    <c-form-feedback [valid]="false">Please select a client.</c-form-feedback>
  </div>
</form>

import { Injectable } from '@angular/core';
import { ClientService } from '../../shared/services/clients.service';
import { BehaviorSubject, Observable, Subject, combineLatest, map, merge, of, shareReplay, switchMap } from 'rxjs';
import {Campaign, NewCampaign} from 'src/app/shared/models/models';
import { ApiHandlerService } from 'source/app/shared/services/api-handler.service';
import { StorageService } from 'source/app/shared/services/storage.service';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  // use RXJS and observables.
  // campaigns is for a client. Get the selected client from the client service (selectedClient$).
  // CRUD operations for campaigns

  public campaigns$: Observable<Campaign[]> = of([]); // Assign an initial value of an empty array to 'clients$'

  private selectedCampaignSubject = new BehaviorSubject<Campaign|null>(null);
  public selectedCampaign$: Observable<Campaign | null> = this.selectedCampaignSubject.asObservable();
  public selectedCampaign: Campaign | undefined;
  // subscribe to the selectedClient$ observable and save the client_id to clientID variable.
  clientId: number=0;

  private campaignsChangedSubject = new BehaviorSubject<void>(undefined);
  campaignsChanged$ = this.campaignsChangedSubject.asObservable();

  constructor(
    private clientService: ClientService,
    private api: ApiHandlerService,
    private storageService: StorageService
    ) {
      this.campaignsChangedSubject.next();
      this.campaigns$ = combineLatest([
        this.clientService.selectedClient$,
        this.campaignsChanged$]
      ).pipe(
        switchMap(([client, campaignsChanged]): Observable<Campaign[]> => {
          // Get the clientId from the first element of the array
          this.clientId = client?.clientID ?? 0;
          return this.getCampaigns();
        }),
        shareReplay(1)
      );
      this.selectedCampaign$.subscribe((campaign) =>{
        if (campaign){
          this.selectedCampaign = campaign;
        }else{
          this.setCampaign(0);
        }
      })
  }

  getCampaigns(clientId = this.clientId) {
    if(clientId === 0) return of([]);
    return this.api.GetAll(ApiHandlerService.urls.campaignsGetAll,{clientId}).pipe(
      switchMap((response: any) => {
        if (response.isSuccess) {
          const campaigns = response.campaigns;
          return of(campaigns);
        } else {
          this.selectedCampaignSubject.next(null);
          return of([]);
        }
      }),
      shareReplay(1)
    );
  }

  setCampaign(id: number) {
    if (!id) {
      id = this.storageService.retrieveData("CurrentCampaignID")??0;
    }
    if(!id || isNaN(id)){
      console.log('No campaign id found');
      if(this.selectedCampaignSubject.value)
        this.selectedCampaignSubject.next(null);
      return;
    }
    this.campaigns$.subscribe((campaigns) => {
      const campaign = campaigns.find((c) => c.campaignID === id);
      if (campaign) {
        this.selectedCampaignSubject.next(campaign);
        this.storageService.storeData("CurrentCampaignID", campaign.campaignID);
      }
    });
  }

  add(campaign: NewCampaign) {
    if (!campaign.clientID) {
      campaign.clientID = this.clientId;
    }
    return this.api.Post(ApiHandlerService.urls.campaignAdd, campaign).pipe(
      switchMap((response: any) => {
        if (response.isSuccess) {
          this.campaignsChangedSubject.next();
          return of(response);
        } else {
          throw new Error(response.errorOnFailure);
        }
      })
    );
  }

  update(campaign: Campaign) {
    return this.api.Put(ApiHandlerService.urls.campaignUpdate, campaign).pipe(
      switchMap((response: any) => {
        if (response.isSuccess) {
          this.campaignsChangedSubject.next();
          return of(response);
        } else {
          throw new Error(response.errorOnFailure);
        }
      })
    );
  }

  delete(campaignID: number) {
    return this.api.Delete(ApiHandlerService.urls.campaignDelete, {campaignID}).pipe(
      switchMap((response: any) => {
        if (response.isSuccess) {
          this.campaignsChangedSubject.next();
          return of(response);
        } else {
          throw new Error(response.errorOnFailure);
        }
      })
    );
  }

  copy(campaignID: number) {
    return this.api.Post(ApiHandlerService.urls.campaignCopy, {campaignID}).pipe(
      switchMap((response: any) => {
        if (response.isSuccess) {
          this.campaignsChangedSubject.next();
          return of(response);
        } else {
          throw new Error(response.errorOnFailure);
        }
      })
    );
  }

  // move campaign to another client
  move(campaignID: number, clientIDToMove: number) {
    return this.api.Post(ApiHandlerService.urls.campaignMove, {campaignID, clientIDToMove}).pipe(
      switchMap((response: any) => {
        if (response.isSuccess) {
          this.campaignsChangedSubject.next();
          return of(response);
        } else {
          throw new Error(response.errorOnFailure);
        }
      })
    );
  }

}

import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cil-speedometer' },
    attributes: {
      loginRequired: true
    }
  },
  {
    title: true,
    name: 'Manage',
    attributes: {
      loginRequired: true
    }
  },
  {
    name: 'Layouts',
    url: '/layouts',
    iconComponent: { name: 'cil-notes' },
    attributes: {
      loginRequired: true
    }
  },
  {
    name: 'Blocks',
    url: '/blocks',
    iconComponent: { name: 'cil-calculator' },
    attributes: {
      loginRequired: true
    }
  },
  {
    title: true,
    name: 'Email Builder'
  },
  {
    name: 'Campaigns',
    url: '/campaigns',
    iconComponent: { name: 'cil-list' },
    attributes: {
      loginRequired: true
    }
  },
  // {
  //   name: 'Email Blocks',
  //   url: '/email-blocks/select',
  //   iconComponent: { name: 'cil-puzzle' },
  //   attributes: {
  //     loginRequired: true
  //   }
  // },
  // {
  //   title: true,
  //   name: 'Extras',
  //   attributes: {
  //     loginRequired: true
  //   }
  // },
  // {
  //   name: 'Pages',
  //   url: '/login',
  //   iconComponent: { name: 'cil-star' },
  //   children: [
  //     {
  //       name: 'Login',
  //       url: '/login'
  //     },
  //     {
  //       name: 'Register',
  //       url: '/register'
  //     },
  //     {
  //       name: 'Error 404',
  //       url: '/404'
  //     },
  //     {
  //       name: 'Error 500',
  //       url: '/500'
  //     }
  //   ]
  // },
];

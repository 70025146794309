import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  map,
  merge,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { Email, EmailBase } from 'src/app/shared/models/emails';
import { ApiHandlerService } from '../../shared/services/api-handler.service';
import { CampaignService } from '../campaigns/campaign.service';

import { StorageService } from 'source/app/shared/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignEmailService {
  private baseEmailChangeSubject = new Subject<void>();
  baseEmailChange$ = this.baseEmailChangeSubject.asObservable();

  emails$!: Observable<EmailBase[]>;

  private selectedEmailSubject = new BehaviorSubject<Email>({} as Email);
  selectedEmail$: Observable<Email> = this.selectedEmailSubject.asObservable();

  private emailContentChangeSubject = new Subject<void>();
  emailContentChange$ = this.emailContentChangeSubject.asObservable();

  selectedBlockEmail$: Observable<Email> = merge(
    this.selectedEmail$,
    this.emailContentChange$
  ).pipe(
    switchMap(() => {
      const emailID = this.selectedEmailSubject.value?.emailID ?? 0;
      this.storeSelectedEmailID(emailID);
      return emailID > 0 ? this.getEmailData(emailID) : of({} as Email);
    }),
    shareReplay(1)
  );

  campaigns$ = this.campaignService.campaigns$;

  constructor(
    private api: ApiHandlerService,
    private campaignService: CampaignService,
    private storageService: StorageService
  ) {
    this.emails$ = combineLatest([
      this.campaignService.selectedCampaign$,
      this.baseEmailChange$.pipe(startWith(undefined)),
    ]).pipe(
      switchMap(([campaign]) => {
        const campaignID = campaign?.campaignID ?? 0;
        return this.getEmails(campaignID);
      }),
      shareReplay(1)
    );
  }

  emailContentChanged() {
    this.emailContentChangeSubject.next();
  }

  storeSelectedEmailID(emailID: number) {
    if (emailID) {
      this.storageService.storeData('CurrentEmailID', emailID);
    }
  }

  setEmail(email: EmailBase) {
    let id =
      email?.emailID || this.storageService.retrieveData('CurrentEmailID') || 0;
    if (!email?.emailID && id) {
      this.emails$.pipe(take(1)).subscribe((emails) => {
        const selectedEmail = emails.find((e) => e.emailID === id);
        if (selectedEmail) {
          email = selectedEmail;
        }
      });
    }
    if (email?.emailID) {
      this.storeSelectedEmailID(email.emailID);
      this.selectedEmailSubject.next(email);
    }
  }

  getEmails(campaignID: number) {
    if (campaignID === 0) {
      return of([]);
    }
    return this.api
      .GetAll(ApiHandlerService.urls.email.getAll, { campaignID })
      .pipe(
        map((response: any) => (response.isSuccess ? response.emails : [])),
        shareReplay(1)
      );
  }

  getEmailData(emailID: number): Observable<any> {
    if (!emailID) {
      return of({} as Email);
    }
    return this.api.Get(ApiHandlerService.urls.email.get, { emailID }).pipe(
      map((response: any) => {
        if (response.isSuccess) {
          const parseJSON = (value: string) => {
            if (!value) {
              return {};
            }
            try {
              return JSON.parse(value);
            } catch (error: any) {
              console.log('Error in parsing JSON Value', value);
              console.error('Error in parsing JSON Error', error.message);
              return {};
            }
          };
          const email = response;
          email.emailBlocks = email.emailBlocks.map((block: any) => ({
            ...block,
            blockData: parseJSON(block.blockData),
            schema: parseJSON(block.schema),
            uI_Schema: parseJSON(block.uI_Schema),
            aiPrompt: block.aiPrompt,
          }));

          return email;
        } else {
          throw new Error(response.errorOnFailure);
        }
      }),
      shareReplay(1)
    );
  }

  addEmail(email: EmailBase) {
    return this.api.Post(ApiHandlerService.urls.email.add, email).pipe(
      switchMap((response: any) => {
        if (response.isSuccess) {
          this.baseEmailChangeSubject.next();
          return of(response);
        } else {
          throw new Error(response.errorOnFailure);
        }
      }),
      catchError((error) => {
        console.error('An error occurred', error);
        return of([]);
      })
    );
  }

  updateEmail(email: EmailBase) {
    return this.api.Put(ApiHandlerService.urls.email.update, email).pipe(
      switchMap((response: any) => {
        if (response.isSuccess) {
          this.baseEmailChangeSubject.next();
          return of(response);
        } else {
          throw new Error(response.errorOnFailure);
        }
      }),
      catchError((error) => {
        console.error('An error occurred', error);
        return of([]);
      })
    );
  }

  deleteEmail(emailID: number) {
    return this.api
      .Delete(ApiHandlerService.urls.email.delete, { emailID })
      .pipe(
        switchMap((response: any) => {
          if (response.isSuccess) {
            this.baseEmailChangeSubject.next();
            return of(response);
          } else {
            throw new Error(response.errorOnFailure);
          }
        })
      );
  }

  copyEmail(emailID: number) {
    var newEmailName = '';
    return this.api
      .Post(ApiHandlerService.urls.email.copy, { emailID, newEmailName })
      .pipe(
        switchMap((response: any) => {
          if (response.isSuccess) {
            this.baseEmailChangeSubject.next();
            return of(response);
          } else {
            throw new Error(response.errorOnFailure);
          }
        })
      );
  }

  moveEmail(emailID: number, campaignIDToMove: number) {
    return this.api
      .Post(ApiHandlerService.urls.email.move, {
        emailID,
        campaignID: campaignIDToMove,
      })
      .pipe(
        switchMap((response: any) => {
          if (response.isSuccess) {
            this.baseEmailChangeSubject.next();
            return of(response);
          } else {
            throw new Error(response.errorOnFailure);
          }
        })
      );
  }

  generateEmail(emailID: number) {
    if (!emailID) {
      throw new Error('Email ID is required');
    }
    return this.api
      .Get(ApiHandlerService.urls.email.generate + '?emailID=' + emailID)
      .pipe(
        switchMap((response: any) => {
          if (response.isSuccess) {
            this.baseEmailChangeSubject.next();
            return of(response); // Return the response as an observable
          } else {
            throw new Error(response.errorOnFailure);
          }
        })
      );
  }

  //Generate Data using AI
  generateDataUsingAI(emailID: number, emailBlockID: number = -1) {
    if (!emailID) {
      throw new Error('Email ID is required');
    }
    return this.api
      .Get(
        ApiHandlerService.urls.email.generateDataUsingAI +
          '?emailID=' +
          emailID +
          '&emailBlockID=' +
          emailBlockID
      )
      .pipe(
        switchMap((response: any) => {
          if (response.isSuccess) {
            this.baseEmailChangeSubject.next();
            return of(response); // Return the response as an observable
          } else {
            throw new Error(response.errorOnFailure);
          }
        })
      );
  }
}

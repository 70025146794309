<div class="d-flex justify-content-between align-items-center pb-4">
  <div>
    <button cButton color="primary" (click)="back()">
      <svg [cIcon]="icons.cilArrowThickLeft" class="me-2"></svg>Back</button>
  </div>
  <ng-container *ngIf="emails$|async as emails">
    <ng-container *ngIf="selectedEmail$|async as selectedEmail">
      <!-- Display the email name -->
      <ng-container *ngIf="campaign$|async as campaign">
        <div>
        <span class="small dark"> ({{campaign.campaignID}}) </span>
        <span class="h5 green">{{campaign.campaignName}} /</span>
        <span class="small dark"> ({{selectedEmail.emailID}}) </span>
        <span class="h6 blue">{{ selectedEmail.emailName }}</span>

        </div>

      </ng-container>
      <div>
        <button cButton color="light" (click)="previousEmail(emails, selectedEmail)">
          <svg [cIcon]="icons.cilArrowThickLeft"></svg> </button>

        <button cButton color="light" (click)="nextEmail(emails, selectedEmail)">
          <svg [cIcon]="icons.cilArrowThickRight"></svg></button>
      </div>
    </ng-container>
  </ng-container>
</div>

import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  createUrlTreeFromSnapshot,
} from '@angular/router';
import { map } from 'rxjs';
import {AuthService} from '../services/auth.service';

export const authGuard = (next: ActivatedRouteSnapshot) => {
  return inject(AuthService)
    .isAuthenticated$
    .pipe(
      map((isLoggedIn) =>
        isLoggedIn ? true : createUrlTreeFromSnapshot(next, ['/', 'login'])
      )
    );
};

<ng-container *ngIf="messages$ | async as messages">
  <c-toaster placement="bottom-end" position="absolute">
    <ng-container *ngFor="let message of messages">
      <c-toast
        [autohide]="true"
        [delay]="message.duration !== undefined ? message.duration : 4000"
        [fade]="true"
        [visible]="true"
        [color]="message.color || 'light'"
        >
        <c-toast-header [closeButton]="true">
          <strong>{{ message.title || 'Notification' }}</strong>
        </c-toast-header>
        <c-toast-body>{{ message.message }}</c-toast-body>
      </c-toast>
    </ng-container>
  </c-toaster>
</ng-container>

import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, catchError, tap, throwError } from 'rxjs';

import { ApiHandlerService } from './api-handler.service';
import { SharedItemsService } from './shared-items.service';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private loginToken = '';
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  private isTokenChecked = false;

  constructor(
    private api: ApiHandlerService,
    private storageService: StorageService,
    private messagesService: SharedItemsService
  ) {
    const authData = this.storageService.retrieveData('auth');
    this.loginToken = authData?.loginToken ?? '';
    this.isAuthenticatedSubject.next(!!this.loginToken);
  }

  getLoginToken(): string {
    if (!this.loginToken && !this.isTokenChecked) {
      const authData = this.storageService.retrieveData('auth');
      if (authData && authData.loginToken) {
        this.loginToken = authData.loginToken;
      }
      this.isTokenChecked = true;
    }
    return this.loginToken;
  }

  public saveToken(loginToken: string): void {
    this.loginToken = loginToken;
    const authData = {
      loginToken,
    };
    this.storageService.storeData('auth', authData);
    this.isAuthenticatedSubject.next(true);
  }

  public async logout(): Promise<void> {
    this.isAuthenticatedSubject.next(false);
    this.storageService.deleteData('auth');
    this.loginToken = '';
  }

  public isAuthenticated(): boolean {
    return !!this.loginToken;
  }

 login(email: string): Observable<any> {
    return this.api.Post(ApiHandlerService.urls.login, { email }).pipe(
      tap(response => console.log('Login response', response)),
      catchError(error => this.handleError(error))
    );
  }

  finalizeLogin(twoFactorToken: string, otp: string): Observable<Object> {
    return this.api.Post(ApiHandlerService.urls.finaliseLogin, { twoFactorToken, otp }).pipe(
      tap((response: Object) => {
        if ((response as any).isSuccess && (response as any).loginToken) {
          this.saveToken((response as any).loginToken);
        } else {
          console.error('An error occurred while finalizing login');
        }
      }),
      catchError(error => this.handleError(error))
    );
  }

  handleError(error: any): Observable<never> {
    this.messagesService.error('Login: An error occurred');
    return throwError(() => error);
  }

  // login(email: string): Observable<any> {
  //   return this.api.Post(ApiHandlerService.urls.login, { email }).pipe(
  //     tap((response) => {
  //       console.log('Login response', response);
  //     }),
  //     catchError((error) => {
  //       const message: NotificationMessage = {
  //         title: 'Login Error',
  //         message: 'An error occurred while logging in',
  //         color: 'danger',
  //       };
  //       this.messagesService.showMessages(message);
  //       return throwError(error);
  //     })
  //   );
  // }

  // finalizeLogin(twoFactorToken: string, otp: string): Observable<any> {
  //   return this.api
  //     .Post(ApiHandlerService.urls.finaliseLogin, { twoFactorToken, otp })
  //     .pipe(
  //       // Store the token in local storage
  //       tap((response) => {
  //         if ((response as any)['isSuccess'] && (response as any).loginToken)
  //           this.saveToken((response as any).loginToken);
  //         else console.error('An error occurred while finalizing login');
  //       }),
  //       catchError((error) => {
  //         // Handle error here
  //         return throwError(error);
  //       })
  //     );
  // }

  // // Logout. Remove the token from local storage, set the token in the api service to null and navigate to the login page
  // logout(): void {
  //   // this.storageService.storeData('auth', authData);
  //   this.storageService.deleteData('auth');
  //   this.loginToken = '';
  //   // set selected client to null
  //   this.selectedClientSubject.next(null);
  // }
}

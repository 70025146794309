import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignEmailService } from 'source/app/views/campaign-emails/campaign-email.service';
import { cilArrowThickRight, cilArrowThickLeft } from '@coreui/icons';
import { Email } from '../../models/emails';
import { CampaignService } from 'source/app/views/campaigns/campaign.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnDestroy{

  @Input() backPath: string | undefined;
  subscriptions: Subscription[] = [];
  public icons = { cilArrowThickRight, cilArrowThickLeft };
  emails$ = this.campaignEmailService.emails$;
  selectedEmail$ = this.campaignEmailService.selectedEmail$;
  campaign$ = this.campaignService.selectedCampaign$;
  constructor(
    private campaignEmailService: CampaignEmailService,
    private campaignService: CampaignService,
    private router: Router
  ){
    this.subscriptions.push(
      this.emails$.subscribe(emails => {
        if (emails.length > 0){
          this.campaignEmailService.setEmail({} as Email);
        }
      })
    );
  }

  previousEmail(emails: Email[], selectedEmail: Email) {
    if (emails.length === 0) return;
    const nextIndex = (emails.findIndex(email => email.emailID === selectedEmail.emailID) ?? 0) - 1;
    const lastIndex = emails.length - 1;
    const newIndex = nextIndex < 0 ? lastIndex : nextIndex;
    this.campaignEmailService.setEmail(emails[newIndex]);
  }

  nextEmail(emails: Email[], selectedEmail: Email) {
    if (emails.length === 0) return;
    const nextIndex = (emails.findIndex(email => email.emailID === selectedEmail.emailID)?? 0) + 1;
    const newIndex = nextIndex >= emails.length ? 0 : nextIndex;
    this.campaignEmailService.setEmail(emails[newIndex]);
  }

  back(){
    if (this.backPath){
      this.router.navigateByUrl(this.backPath, { skipLocationChange: true })
          .then(() => this.router.navigate([this.backPath]));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { INavData } from '@coreui/angular';
import { Subscription, map } from 'rxjs';
import { EmailBase } from 'source/app/shared/models/emails';
import { AuthService } from 'source/app/shared/services/auth.service';
import { CampaignEmailService } from 'source/app/views/campaign-emails/campaign-email.service';
import { CampaignService } from 'source/app/views/campaigns/campaign.service';
import { navItems } from './_nav';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  isLoggedIn: boolean = false;
  public navItems = navItems;

  campaignEmails$ = this.campaignEmailService.emails$;
  navItems$ = this.campaignEmails$.pipe(
    map((emails) => {
      return this.getCampaignNavItems(emails);
    })
  );

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private campaignService: CampaignService,
    private campaignEmailService: CampaignEmailService
  ) {
    this.navItems = this.filterNavItems(navItems);
    this.subscriptions.push(
      this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
        this.isLoggedIn = isAuthenticated;
        this.navItems = this.filterNavItems(navItems);
        setTimeout(() => {
          this.cdr.detectChanges();
        });
      })
    );
  }

  filterNavItems(items: INavData[]) {
    return items.filter((item) => {
      // Check the item itself
      if (
        !item.attributes ||
        !item.attributes['loginRequired'] ||
        this.isLoggedIn
      ) {
        // If the item has children, check them as well
        if (item.children) {
          item.children = this.filterNavItems(item.children);
        }
        return true;
      }
      return false;
    });
  }

  getCampaignNavItems(emails: EmailBase[]): INavData[] {
    if (!this.campaignService.selectedCampaign) return [];

    return [
      {
        name: 'Campaign Emails',
        title: true,
      },
      {
        name: this.campaignService.selectedCampaign.campaignName,
        title: false,        
        iconComponent: { name: 'cil-list' },
        children: emails?.map((email: EmailBase) => ({
          name: email.emailName,
          url: `/campaigns/emails/details/${email.emailID}`,
          iconComponent: { name: 'cil-envelope-closed' },
        })) || [],
      },
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

// login.component.ts
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ClientService } from 'source/app/shared/services/clients.service';
import { AuthService } from  'src/app/shared/services/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  isLoggedIn: boolean = false;
  loginForm: FormGroup;
  otpForm: FormGroup;
  twoFactorToken: string = '';
  private destroy$ = new Subject();
  loginError: string = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private clientService: ClientService
  ) {
    this.subscriptions.push(
      this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
        this.isLoggedIn = isAuthenticated;
        this.clientService.setClient();
      })
    );
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.otpForm = this.fb.group({
      otp: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.isLoggedIn) {
      this.router.navigate(['/']);
    }
  }

  onLoginSubmit(): void {
    this.loginError = '';
    if (this.loginForm.valid) {
      this.subscriptions.push(
        this.authService
          .login(this.loginForm.value.email)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response: any) => {
              if (response?.isSuccess) {
                this.twoFactorToken = response.twoFactorToken;
              } else {
                this.loginError = response.errorOnFailure;
              }
            },
            error: (error) => {
              this.loginError = 'An error occurred while logging in';
            },
          })
      );
    }
  }

  onOtpSubmit(): void {
    this.loginError = '';
    if (this.otpForm.valid) {
      this.subscriptions.push(
        this.authService
          .finalizeLogin(this.twoFactorToken, this.otpForm.value.otp)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response: any) => {
              if (response?.isSuccess) {
                this.clientService.setClient();
                this.router.navigate(['/']);
              } else {
                this.loginError = response.errorOnFailure;
              }
            },
            error: (error) => {
              this.loginError = 'An error occurred while finalizing login';
            },
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'source/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private baseKey = environment.localStorageKey;

  constructor() { }

  storeData(key: string, data: any): void {
    const storageKey = this.baseKey + '-' + key;
    localStorage.setItem(storageKey, JSON.stringify(data));
  }

  retrieveData(key: string): any {
    const storageKey = this.baseKey + '-' + key;
    const data = localStorage.getItem(storageKey);
    return data?JSON.parse(data):null;
  }

  deleteData(key: string): void {
    const storageKey = this.baseKey + '-' + key;
    try {
      localStorage.removeItem(storageKey);
    } catch (error) {
      console.error('Error occurred while deleting data from local Storage:');
    }
  }

  hasKey(key: string): boolean {
    const storageKey = this.baseKey + '-' + key;
    return localStorage.getItem(storageKey) !== null;
  }
}

<!--sidebar-->
<c-sidebar
  #sidebar="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/img/brand/emailcraft-logo.png',
      width: 200,
      height: 46,
      alt: 'CoreUI Logo'
    }"
    [brandNarrow]="{
      src: 'assets/img/brand/emailcraft-logo-small.png',
      width: 46,
      height: 46,
      alt: 'CoreUI Logo'
    }"
    routerLink="./"
  />

  <ng-scrollbar pointerEventsMethod="scrollbar">
    <c-sidebar-nav
      [navItems]="navItems"
      dropdownMode="close"
    />
    <!-- Add menus to show emails -->
    <c-sidebar-nav [navItems]="(navItems$|async)??[]" dropdownMode="none" cBgColor="secondary" ></c-sidebar-nav>
  </ng-scrollbar>
  <c-sidebar-toggler
    *ngIf="!sidebar.narrow"
    toggle="unfoldable"
    cSidebarToggle="sidebar"
  />
</c-sidebar>
<app-messages/>
<app-spinner/>
<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar" />
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container fluid  class="h-auto">
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer />
</div>

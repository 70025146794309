import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BlockService } from 'source/app/views/blocks/block.service';
import { SharedItemsService } from '../../services/shared-items.service';
import { Client } from '../../models/models';
import { ListBlock } from '../../models/blocks';

@Component({
  selector: 'app-block-move-form',
  templateUrl: './block-move-form.component.html',
  styleUrl: './block-move-form.component.scss'
})
export class BlockMoveFormComponent implements OnInit, OnDestroy {

  blockMoveForm!: FormGroup;
  subscriptions: Subscription[] = []
  @Input() clients: Client[] | undefined;
  @Input() clientId: number | undefined;
  @Input() block: ListBlock | null = null;
  @Output() moveSuccess = new EventEmitter();
  filteredClients: Client[] = [];

  constructor(
    private fb: FormBuilder,
    private blockService: BlockService,
    private messageService: SharedItemsService
  ){

  }

  ngOnInit(): void {
    if (this.clients && this.clientId){
      this.clients?.forEach(client => {
        if (client.clientID != this.clientId){
          this.filteredClients.push(client);
        }
      })
      this.clientId = this.filteredClients[0]?.clientID??undefined;
    }
    this.blockMoveForm = this.fb.group({
      ClientID: [this.clientId, Validators.required]
    });
  }

  validateForm(): boolean {
    return this.blockMoveForm.valid;
  }

  save(): void {
    this.blockMoveForm.markAllAsTouched();
    const formValues = this.blockMoveForm.value
    let saveObservable: any;
    saveObservable = this.blockService.moveBlock(this.block?.blockID?? -1, formValues.ClientID);
    this.subscriptions.push(
      saveObservable.subscribe({
        next: () => {
          this.moveSuccess.emit();
        },
        error: (error: any) => {
          this.messageService.showMessages({ title: 'Error', message: error?.message, color: 'danger' });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}

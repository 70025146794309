import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ListBlock } from '../../models/blocks';
import { cilPlus, cilXCircle } from '@coreui/icons';
import { cilCopy, cilCog, cilCursor } from '@coreui/icons';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientService } from '../../services/clients.service';
import { Client } from '../../models/models';
import { BlockService } from 'source/app/views/blocks/block.service';
import { SharedItemsService } from '../../services/shared-items.service';
import { BlockCopyFormComponent } from '../block-copy-form/block-copy-form.component';
import { BlockMoveFormComponent } from '../block-move-form/block-move-form.component';

@Component({
  selector: 'app-block-tile',
  templateUrl: './block-tile.component.html',
  styleUrl: './block-tile.component.scss'
})

export class BlockTileComponent implements OnDestroy{

  @ViewChild(BlockCopyFormComponent) private blockCopyComponent =
    {} as BlockCopyFormComponent;
  @ViewChild(BlockMoveFormComponent) private blockMoveComponent =
    {} as BlockMoveFormComponent;
  @Input() block: ListBlock | null = null;
  @Input() source: string | undefined;
  @Input() clients: Client[] | undefined;
  @Output() blockSelect = new EventEmitter();
  @Output() deleteConfirmation = new EventEmitter();
  @Output() addImage = new EventEmitter();
  @Output() addBlock = new EventEmitter();
  @Output() copyMoveSuccess = new EventEmitter();

  subscriptions: Subscription[] = [];
  icons = { cilPlus, cilXCircle, cilCopy, cilCog, cilCursor };
  copyBlockFormVisible = false;
  moveBlockFormVisible = false;
  blockToDuplicate = 0;
  selectedClient$ = this.clientService.selectedClient$;
  clientId = -1;
  type = "";

  constructor(
    private clientService: ClientService
  ){
    this.subscriptions.push(
      this.selectedClient$.subscribe(client =>{
        this.clientId = client?.clientID?? -1;
      })
    );
  }

  selectBlock(block: ListBlock | null){
    this.blockSelect.emit(block);
  }

  openDeleteConfirmation(blockID: number){
    this.deleteConfirmation.emit(blockID);
  }

  addEditImage(block: ListBlock | null){
    this.addImage.emit(block);
  }

  addNewBlock(blockID: number){
    this.addBlock.emit(blockID);
  }

  copyBlock(blockId: number){
    this.blockToDuplicate = blockId;
    this.copyBlockFormVisible = true;
  }

  toggleCopyFormVisible() {
    this.copyBlockFormVisible = !this.copyBlockFormVisible;
  }

  handleCopyBlockChange(event: any) {
    this.copyBlockFormVisible = event;
  }

  copyBlockToClient(){
    if (!this.blockCopyComponent.validateForm()) return;
    this.blockCopyComponent.save();
  }

  moveBlock(blockId: number){
    this.blockToDuplicate = blockId;
    this.moveBlockFormVisible = true;
  }

  toggleMoveFormVisible() {
    this.moveBlockFormVisible = !this.moveBlockFormVisible;
  }

  handleMoveBlockChange(event: any) {
    this.moveBlockFormVisible = event;
  }

  moveBlockToClient(){
    if (!this.blockMoveComponent.validateForm()) return;
    this.blockMoveComponent.save();
  }

  copySuccess(){
    this.copyMoveSuccess.emit();
    this.copyBlockFormVisible = false;
    this.moveBlockFormVisible = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

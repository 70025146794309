import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, of, shareReplay, switchMap, take } from 'rxjs';
import { Client } from 'src/app/shared/models/models';
import { Router } from '@angular/router';
import { ApiHandlerService } from './api-handler.service';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  // create two observable properties for the clients and selectedClient
  public clients$!: Observable<Client[]>;
  private selectedClientSubject = new BehaviorSubject<Client | null>(null); // Assign an initial value of undefined to 'selectedClientSubject'
  public selectedClient$: Observable<Client | null> =
    this.selectedClientSubject.asObservable();
  public selectedClientID: number = 0;
  constructor(
    private api: ApiHandlerService,
    private auth: AuthService,
    private storageService: StorageService
  ) {
    this.clients$ = this.auth.isAuthenticated$.pipe(
      switchMap(isAuthenticated => isAuthenticated ? this.getClients() : of([]))
    );
  }

  // Retrieve a list of clients and store them in the clients observable
  getClients(): Observable<Client[]> {
    return this.api.GetAll(ApiHandlerService.urls.clientGetAll).pipe(
      map((response: any) => {
        if (response.isSuccess) {
          const clients = response.clients;
          // this.clients$ = of(clients);
          return clients;
        } else {
          return of([]);
        }
      }),
      shareReplay(1)
    );
  }

  // set the selected client. if no client is found, raise an error
  setClient(id: number = 0) {
    if (!id) {
      id = this.storageService.retrieveData('CurrentClientID') ?? 0;
    }
    if (!isNaN(id)) {
      this.clients$.pipe(take(1)).subscribe((clients) => {
        const client = clients.find((c) => c.clientID === id);
        this.selectedClientSubject.next(client ?? clients?.[0] ?? null);
        if (client || (clients && clients.length > 0)) {
          this.selectedClientID = id;
          this.storageService.storeData('CurrentClientID', id);
        }
      });
    }
  }
}

import { Component } from '@angular/core';
import { LoadService } from '../../services/load.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [
    CommonModule
  ],
  template: `
    <div *ngIf="loading$ | async" class="spinner-container">
      <img src="assets/img/loading.gif" alt="Loading..." width="100" height="100"><br>
    </div>
  `,
  styles: [`
    .spinner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5); /* semi-transparent white background */
      z-index: 9999; /* ensure the spinner is on top */
    }
  `]
})
export class SpinnerComponent {
  loading$ = this.loadService.loading$;
  constructor(private loadService: LoadService) {}
}

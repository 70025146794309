import { ClientService } from './../../../shared/services/clients.service';
import { Component, Input, OnDestroy } from '@angular/core';
import {Client} from 'src/app/shared/models/models';
import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { Subscription } from 'rxjs';
import { AuthService } from 'source/app/shared/services/auth.service';
import { cilSpeedometer, cilNotes, cilCalculator, cilList } from '@coreui/icons';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent implements OnDestroy {
  @Input() sidebarId: string = 'sidebar';
  private subscriptions: Subscription[] = [];
  public icons = { cilSpeedometer, cilNotes, cilCalculator, cilList };

  public newMessages = new Array(4);
  public newTasks = new Array(5);
  public newNotifications = new Array(5);
  public clientsList$ = this.clientsService.clients$;
  public selectedClient$ = this.clientsService.selectedClient$;
  public isLoggedIn: boolean = false;

  constructor(
    private classToggler: ClassToggleService,
    private clientsService: ClientService,
    private authService: AuthService
  ) {
    super();
    this.subscriptions.push(
      this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
        this.isLoggedIn = isAuthenticated;
        this.clientsService.setClient();
      })
    );
  }
  // set client
  setClient(client: Client) {
    this.clientsService.setClient(client.clientID);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}

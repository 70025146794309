import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  concatMap,
  finalize,
  Observable,
  of,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadService {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor() {
    console.log('Loading service created ...');
  }
  showLoaderUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => this.show()),
      concatMap(() => obs$),
      finalize(() => this.hide())
    );
  }

  show(): void {
    this.loadingSubject.next(true);
  }
  hide(): void {
    this.loadingSubject.next(false);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HIGHLIGHT_OPTIONS, HighlightOptions } from 'ngx-highlightjs';
import { NavbarComponent } from './components/navbar/navbar.component';
import {
  ButtonModule,
  CardModule,
  GridModule,
  ModalModule,
  FormModule
} from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { NavbarCampaignComponent } from './components/navbar-campaign/navbar-campaign.component';
import { BlockTileComponent } from './components/block-tile/block-tile.component';
import { ConfirmationModalComponent } from "../shared/components/confirmation-modal/confirmation-modal.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockCopyFormComponent } from './components/block-copy-form/block-copy-form.component';
import { BlockMoveFormComponent } from './components/block-move-form/block-move-form.component';


@NgModule({
  declarations: [
    NavbarComponent,
    NavbarCampaignComponent,
    BlockTileComponent,
    BlockCopyFormComponent,
    BlockMoveFormComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    GridModule,
    IconModule,
    CardModule,
    ConfirmationModalComponent,
    FormsModule,
    ReactiveFormsModule,
    FormModule
  ],
  exports:[
    NavbarComponent,
    NavbarCampaignComponent,
    BlockTileComponent
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: <HighlightOptions>{
        lineNumbers: true,
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'),
        themePath: 'node_modules/highlight.js/styles/github.css',
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml'),
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
  ],
})
export class SharedItemsModule { }

<form cForm [formGroup]="blockCopyForm">
  <div class="mb-3">
    <label cLabel for="blockType">Name</label>
    <input cFormControl id="blockType" type="text" formControlName="Type"
      [valid]="blockCopyForm.controls['Type'].valid" />
    <c-form-feedback [valid]="false">Block Name is required.</c-form-feedback>
  </div>
  <div class="mb-2">
    <label cLabel for="clientID">Select client where block will be copied to</label>
    <select cSelect id="clientID" formControlName="ClientID" [valid]="blockCopyForm.controls['ClientID'].valid">
      <option *ngFor="let client of clients" [value]="client.clientID">
        {{client.clientName}}
      </option>
    </select>
    <c-form-feedback [valid]="false">Please select a client.</c-form-feedback>
  </div>
</form>

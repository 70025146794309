<div class="d-flex justify-content-between align-items-center pb-4">
  <div>
    <button cButton color="primary" (click)="back()">
      <svg [cIcon]="icons.cilArrowThickLeft" class="me-2"></svg>Back</button>
  </div>
  <ng-container *ngIf="campaigns$|async as campaigns">
    <ng-container *ngIf="selectedCampaign$|async as selectedCampaign">
      <div>
        <span class="small dark"> ({{selectedCampaign.campaignID}}) </span>
        <span class="h5 green">{{selectedCampaign.campaignName}} Emails</span>
      </div>

      <div>
        <button cButton color="light" (click)="previousCampaign(campaigns, selectedCampaign)">
          <svg [cIcon]="icons.cilArrowThickLeft"></svg> </button>

        <button cButton color="light" (click)="nextCampaign(campaigns, selectedCampaign)">
          <svg [cIcon]="icons.cilArrowThickRight"></svg></button>

      </div>
    </ng-container>
  </ng-container>
</div>

import { Injectable } from "@angular/core";
import { IApiBaseActions, ParamsType } from "../models/api-base-actions.interface";
import { HttpClient, HttpParams } from "@angular/common/http";
import { tap } from "rxjs/internal/operators/tap";
import { SharedItemsService } from "./shared-items.service";
import { environment } from "source/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiHandlerService implements IApiBaseActions {
  constructor(
    public httpClient: HttpClient,
    private messageService:SharedItemsService
    ) {
  }
  private readonly API_ENDPOINT = environment.API_BASE_URL;

  public static urls = {
    login: 'user/login',
    finaliseLogin: 'user/login/finalise',
    logout: 'user/logout',
    clientGetAll: 'client/getall',
    clientGet: 'client/get',

    emailLayoutGetAll: 'emailLayout/getall',
    emailLayoutAdd: 'emailLayout/add',
    emailLayoutUpdate: 'emailLayout/update',
    emailLayoutDelete: 'emailLayout/delete',

    //campaigns
    campaignsGetAll: 'campaign/getall',
    campaignUpdate: 'campaign/update',
    campaignAdd: 'campaign/add',
    campaignDelete: 'campaign/delete',
    campaignCopy: 'campaign/copy',
    campaignMove: 'campaign/move',

    //dashboard
    dashboardCampaigns: 'dashboard/getAllCampaigns',
    dashboardEmails: 'dashboard/getAllEmails',
    dashboardRecentlyModified: 'dashboard/getRecentModifications',
    dashboardBlocksUsage: 'dashboard/getBlocksUsage',

    // block
    blockGetAll: 'block/getall',
    blockGet: 'block/get',
    blockAdd: 'block/add',
    blockUpdate: 'block/update',
    blockDelete: 'block/delete',
    blockCopy: 'block/copy',
    blockMove: 'block/move',
    blockUpdateSampleImage: 'block/sampleimage/save',
    convertHtmlToTemplate: 'block/convertHtmlToTemplateWithAI',
    generateSchemaWithAI: 'block/generateSchemaWithAI',

    // email
    email : {
      getAll: 'email/getall',
      get: 'email/get',
      add: 'email/add',
      update: 'email/update',
      delete: 'email/delete',
      setVisibility: 'email/setvisibility',
      copy: 'email/copy',
      move: 'email/move',
      globals: {
        add: 'email/globals/add',
        update: 'email/globals/update',
        delete: 'email/globals/delete',
      },
      blocks: {
        add: 'email/blocks/add',
        update: 'email/blocks/update',
        updateData: 'email/blocks/dataupdate',
        delete: 'email/blocks/delete',
        sort: 'email/blocks/sort',
      },
      generate: 'email/generate',
      generateDataUsingAI: 'email/getEmailDataUsingAI',
    }


  };

  Get(url: string, params?: ParamsType) {
    return this.httpClient
      .get(`${this.API_ENDPOINT}${url}`, {params: this.createParams(params)})
      .pipe(tap((x) => this.HandleResponse(x)));
  }


  GetAll(url: string, params?: ParamsType) {
    return this.httpClient
      .get(`${this.API_ENDPOINT}${url}`, {params: this.createParams(params)})
      .pipe(tap((x) => this.HandleResponse(x)));
  }

  Post(url: string, data: any, params?: ParamsType) {
    return this.httpClient
      .post(`${this.API_ENDPOINT}${url}`, data, {params: this.createParams(params)})
      .pipe(tap((x) => this.HandleResponse(x)));
  }

  Delete(url: string, params?: ParamsType) {
    return this.httpClient
      .delete(`${this.API_ENDPOINT}${url}`, {params: this.createParams(params)})
      .pipe(tap((x) => this.HandleResponse(x)));
  }

  Put(url: string, data: any, params?: ParamsType) {
    return this.httpClient
      .put(`${this.API_ENDPOINT}${url}`, data, {params: this.createParams(params)})
      .pipe(tap((x) => this.HandleResponse(x)));
  }

  HandleResponse(response: any) {
    if (response.Status === 500) {
      // alert(responseMessage.serverError);
      this.messageService.error(response?.Message?.message??response?.message, "Server Error");
    }
    if (response.Status === 401) {
      // alert(responseMessage.unauthorized);
      this.messageService.error(response?.Message?.message??response?.message, "Unauthorized");
    }
    if (response && response?.isSuccess === false) {
      this.messageService.error(response?.errorOnFailure, "Error");
    }
  }

  createParams(params?: ParamsType) {
    let httpParams = new HttpParams();
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        httpParams = httpParams.append(key, value);
      });
    }
    return httpParams;
  }
}


import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { NotificationMessage } from '../models/notification-messages';
import { AbstractControl } from '@angular/forms';
import Ajv from 'ajv';

@Injectable({
  providedIn: 'root'
})
export class SharedItemsService {
  private ajv: Ajv;
  private subject = new BehaviorSubject<NotificationMessage[]>([]);

  constructor() {
    this.ajv = new Ajv();
    // The 'bind' method is used here to ensure that the 'this' keyword inside the 'validateJsonSchema' and 'jsonValidator'
    // methods always refers to the current instance of the class, even when these methods are passed around as callbacks
    // or used in event handlers.
    this.validateJsonSchema = this.validateJsonSchema.bind(this);
    this.jsonValidator = this.jsonValidator.bind(this);
  }

    messages$: Observable<NotificationMessage[]> = this.subject.asObservable()
        .pipe(
            filter(messages => messages && messages.length > 0)
        );

    showMessages(...messages: NotificationMessage[]) {
        this.subject.next(messages);
    }

    info(message: string, title: string = 'Info') {
      const messages: NotificationMessage[] = [{ title, message, color: 'info', icon: 'cil-info', duration: 3000 }];
      this.showMessages(...messages);
    }

    success(message: string, title: string = 'Success') {
      const messages: NotificationMessage[] = [{ title, message, color: 'success', icon: 'cil-check', duration: 3000 }];
      this.showMessages(...messages);
    }

    error(message: string, title: string = 'Error') {
      const messages: NotificationMessage[] = [{ title, message, color: 'danger', icon: 'cil-x-circle' }];
      this.showMessages(...messages);
    }

    jsonValidator(control: AbstractControl): {[key: string]: any} | null {
      try {
        if (control.value == "")
        return null; // Empty value accepted
        JSON.parse(control.value);
        return null; // valid JSON
      } catch (error) {
        return { incorrect: true }; // invalid JSON
      }
    }

    validateJsonSchema(control: AbstractControl): {[key: string]: any} | null {
      try {
        if (!control.value)
          return null; // Empty value accepted
        let schemaObject = JSON.parse(control.value);
        this.ajv.compile(schemaObject);
        return null; // valid JSON schema
      } catch (error) {
        console.log('Error:', error); // Log any errors
        return { incorrect: true }; // invalid JSON schema
      }
    }

    validateJsonSchemaFn(schema: object | string): boolean {
      let schemaObject: object;

      if (typeof schema === 'string') {
        try {
          schemaObject = JSON.parse(schema);
        } catch (error) {
          console.error('Invalid JSON string:', error);
          return false;
        }
      } else {
        schemaObject = schema;
      }

      try {
        this.ajv.compile(schemaObject);
        return true;
      } catch (error) {
        console.error('Invalid JSON Schema:', error);
        return false;
      }
    }
}

import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignEmailService } from 'source/app/views/campaign-emails/campaign-email.service';
import { cilArrowThickRight, cilArrowThickLeft } from '@coreui/icons';
import { Email } from '../../models/emails';
import { CampaignService } from 'source/app/views/campaigns/campaign.service';
import { Campaign } from '../../models/models';
import { take } from 'rxjs';

@Component({
  selector: 'app-navbar-campaign',
  templateUrl: './navbar-campaign.component.html',
  styleUrl: './navbar-campaign.component.scss'
})
export class NavbarCampaignComponent {
  @Input() backPath: string | undefined;
  public icons = { cilArrowThickRight, cilArrowThickLeft };
  selectedCampaign$ = this.campaignService.selectedCampaign$;
  campaigns$ = this.campaignService.campaigns$;
  constructor(
    private campaignService: CampaignService,
    private router: Router
  ){}

  previousCampaign(campaigns: Campaign[], selectedCampaign: Campaign) {
    if (campaigns.length === 0) return;
    const nextIndex = (campaigns.findIndex(campaign => campaign.campaignID === selectedCampaign.campaignID) ?? 0) - 1;
    const lastIndex = campaigns.length - 1;
    const newIndex = nextIndex < 0 ? lastIndex : nextIndex;
    this.campaignService.setCampaign(campaigns[newIndex].campaignID);
  }

  nextCampaign(campaigns: Campaign[], selectedCampaign: Campaign) {
    if (campaigns.length === 0) return;
    const nextIndex = (campaigns.findIndex(campaign => campaign.campaignID === selectedCampaign.campaignID)?? 0) + 1;
    const newIndex = nextIndex >= campaigns.length ? 0 : nextIndex;
    this.campaignService.setCampaign(campaigns[newIndex].campaignID);
  }

  back(){
    if (this.backPath){
      this.router.navigateByUrl(this.backPath, { skipLocationChange: true })
          .then(() => this.router.navigate([this.backPath]));
    }
  }

  ngOnDestroy() {
  }
}

<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>
    <c-header-nav class="d-none d-lg-flex me-auto">
      <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          <svg cIcon class="me-2" name="cilSpeedometer"></svg>
          Dashboard
        </a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/blocks" routerLinkActive="active">
          <svg cIcon class="me-2" name="cilCalculator"></svg>Blocks</a>
      </c-nav-item>
      <c-nav-item>
        <a cNavLink routerLink="/campaigns" routerLinkActive="active">
          <svg cIcon class="me-2" name="cilList"></svg>
          Campaigns
        </a>
      </c-nav-item>
    </c-header-nav>
     <!-- Show Client name in h4 size (selectedClinet$.clientName) on the header-->
    <c-header-nav class="ms-6 fw-bold">
      <h2 class="text-info" *ngIf="selectedClient$ | async as selectedClient">{{ selectedClient.clientName }}</h2>
    </c-header-nav>
    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button
      cButton
      color=""
      [caret]="false"
      cDropdownToggle
      class="py-0"
    >
      <c-avatar *ngIf="isLoggedIn"
        cTextColor="primary"
        shape="rounded-1"
        size="md"
        src="./assets/img/avatars/profile-pic.jpg"
        status="success"
      >
      </c-avatar>
    </button>

    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <ng-container *ngIf="isLoggedIn" >
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Client</h6>
      </li>
      <ng-container *ngIf="clientsList$ | async as clientsList">
          <li *ngFor="let client of clientsList">
            <a cDropdownItem (click)="setClient(client)">
            <ng-container *ngIf="selectedClient$ | async as selectedClient">
              <svg cIcon class="me-2 fore-color-green" name="cilCheck" *ngIf="client.clientID === selectedClient.clientID"></svg>
              <svg cIcon class="me-2" name="" *ngIf="client.clientID != selectedClient.clientID"></svg>
            </ng-container>
              {{ client.clientName }}
            </a>
          </li>
      </ng-container>

      <!-- <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Account</h6>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilBell"  ></svg>
          Updates
          <c-badge class="ms-2 float-end" color="info">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilEnvelopeOpen"></svg>
          Messages
          <c-badge class="ms-2 float-end" color="success">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilTask"></svg>
          Tasks
          <c-badge class="ms-2 float-end" color="danger">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name='cilCommentSquare'></svg>
          Comments
          <c-badge class="ms-auto" color="warning">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Settings</h6>
      </li>
      <li></li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Settings
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilCreditCard"></svg>
          Payments
          <c-badge class="ms-2 float-end" color="secondary">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilFile"></svg>
          Projects
          <c-badge class="ms-2 float-end" color="primary">
            42
          </c-badge>
        </a>
      </li> -->
      <li>
        <hr cDropdownDivider/>
      </li>
      <!-- <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Lock Account
        </a>
      </li> -->

      <li>
        <a routerLink="/pages/logout" cDropdownItem>
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          Logout
        </a>
      </li>
    </ng-container>
    <li *ngIf="!isLoggedIn">
      <a routerLink="/pages/login" cDropdownItem>
        <svg cIcon class="me-2" name="cilArrowRight"></svg>
        Login
      </a>
    </li>
    </ul>
  </c-dropdown>
</ng-template>


import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { SharedItemsService } from '../../services/shared-items.service';
import {
  ToastModule,
} from '@coreui/angular';
import { NotificationMessage } from '../../models/notification-messages';
import { CommonModule } from '@angular/common';
import { IconModule, IconSetService } from '@coreui/icons-angular';
import { cilListNumbered } from '@coreui/icons';

@Component({
  selector: 'app-messages',
  standalone: true,
    imports: [
      CommonModule,
      ToastModule,
      IconModule
    ],
    providers: [IconSetService],
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.scss'
})
export class MessagesComponent implements OnInit {

  visible = false;

  messages$: Observable<NotificationMessage[]> = new Observable<NotificationMessage[]>();


  constructor(public messagesService: SharedItemsService) {

      console.log("Created messages component");

  }

  ngOnInit() {
      this.messages$ = this.messagesService.messages$
          .pipe(
              tap(() => this.visible = true)
          );
  }

  onClose() {
      this.visible = false;

  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Client } from '../../models/models';
import { BlockService } from 'source/app/views/blocks/block.service';
import { Subscription } from 'rxjs';
import { SharedItemsService } from '../../services/shared-items.service';
import { ListBlock } from '../../models/blocks';

@Component({
  selector: 'app-block-copy-form',
  templateUrl: './block-copy-form.component.html',
  styleUrl: './block-copy-form.component.scss'
})

export class BlockCopyFormComponent implements OnInit,OnDestroy{

  blockCopyForm!: FormGroup;
  @Input() clients: Client[] | undefined;
  @Input() clientId: number | undefined;
  @Input() block: ListBlock | null = null;
  @Output() copySuccess = new EventEmitter();
  type: string = "";
  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private blockService: BlockService,
    private messageService:SharedItemsService
  ){}

  ngOnInit(): void {
    if(this.block){
      this.type = this.block.type;
    }
    this.blockCopyForm = this.fb.group({
      ClientID: [this.clientId, Validators.required],
      Type: [this.type, Validators.required]
    });
  }

  validateForm(): boolean {
    return this.blockCopyForm.valid;
  }

  save(): void {
    this.blockCopyForm.markAllAsTouched();
    let saveObservable: any;
    const formValue = this.blockCopyForm.value;
    saveObservable = this.blockService.copyBlock(this.block?.blockID??-1, formValue.ClientID, formValue.Type);
    this.subscriptions.push(
      saveObservable.subscribe({
        next: () => {
          this.copySuccess.emit();
        },
        error: (error: any) => {
          this.messageService.error(error);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

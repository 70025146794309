import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedItemsService } from '../shared/services/shared-items.service';
import { AuthService } from '../shared/services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private messageService = inject(SharedItemsService);
  private authService = inject(AuthService);
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle the error here
        let errorMessage = '';
        if (error.error.errorOnFailure) {
          errorMessage = error.error.errorOnFailure;
        } else if (error.error.Message) {
          errorMessage = error.error.Message;
        } else {
          errorMessage = error.message;
        }
        this.messageService.error(errorMessage, 'Server Error');

        // if response code is 401 then redirect to login page
        if (error.status === 401) {
          this.authService.logout();
          // location.reload(true);
        }
        console.error('Error occurred:', error);
        return throwError(() => error);
      })
    );
  }
}

<c-modal id="confirmationModal"  [(visible)]="visible" [ngClass]="{'bg': showbg}">
  <c-modal-header>
    <h5 cModalTitle>{{title}}</h5>
    <button (click)="cancelled()" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>
    {{message}}
  </c-modal-body>
  <c-modal-footer>
    <button (click)="cancelled()" cButton color="secondary">
      Cancel
    </button>
    <button cButton color="danger" (click)="confirmed()">Confirm</button>
  </c-modal-footer>
</c-modal>

<!-- login.component.html -->
<div class="bg-light min-vh-50 d-flex flex-row align-items-center">
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="8">
        <c-card-group>
          <c-card class="p-4">
            <c-card-body>
              <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()" *ngIf="!twoFactorToken">
                <h1>Login</h1>
                <p class="text-medium-emphasis">Enter your email address</p>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input formControlName="email" autoComplete="username" cFormControl placeholder="Email" />
                  <c-form-feedback [valid]="false">Please enter a valid email address.</c-form-feedback>
                </c-input-group>
                <div *ngIf="loginError" class="alert alert-danger" role="alert">{{ loginError }}</div>
                <c-row>
                  <c-col xs="6">
                    <button cButton class="px-4" color="primary" type="submit">
                      Next
                    </button>
                  </c-col>
                </c-row>
              </form>
              <form [formGroup]="otpForm" (ngSubmit)="onOtpSubmit()" *ngIf="twoFactorToken">
                <h1>Login</h1>
                <p class="text-medium-emphasis">Enter the OTP sent to your email</p>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input formControlName="otp" cFormControl placeholder="OTP" />
                  <c-form-feedback [valid]="false">Please enter the valid OTP.</c-form-feedback>
                </c-input-group>
                <div *ngIf="loginError" class="alert alert-danger" role="alert">{{ loginError }}</div>
                <c-row>
                  <c-col xs="6">
                    <button cButton class="px-4" color="primary" type="submit">
                      Login
                    </button>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>
        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>

<c-card [ngClass]="{'container-block-page': source == 'block-listing', 'container-email-page': source != 'block-listing'}">
  <img class="img-container" cCardImg="top" src="{{block?.sampleImageURL}}"
    onerror="this.src='assets/img/placeholder.svg'" style="height: 300px; object-fit: contain;">
  <c-card-body cBgColor="light">
    <h5 cCardTitle>{{block?.type}}</h5>
    <p cCardText class="description">
      {{block?.description}}
    </p>
  </c-card-body>
  <c-card-footer>
    <div *ngIf="source == 'block-listing'" class="d-grid gap-2 d-md-flex justify-content-md-end mb-2">
      <button cButton color="primary" size="sm" (click)="selectBlock(block)">Edit</button>
      <button cButton color="danger" size="sm" (click)="openDeleteConfirmation(block?.blockID??0)">Delete</button>
      <button cButton color="info" size="sm" (click)="addEditImage(block)">Image</button>
    </div>
    <div *ngIf="source == 'block-listing'" class="d-grid gap-2 d-md-flex justify-content-md-end mb-1">
      <button cButton color="light" size="sm" (click)="copyBlock(block?.blockID??0)">
        <svg [cIcon]="icons.cilCopy" class="me-2"></svg>Duplicate
      </button>
      <button cButton color="light" size="sm" (click)="moveBlock(block?.blockID??0)">
        <svg [cIcon]="icons.cilCursor" class="me-2"></svg>Move
      </button>
    </div>
    <div *ngIf="source == 'email-block-listing'" class="d-grid gap-2 d-md-flex justify-content-md-end pb-1">
      <div class="d-flex justify-content-between align-items-center">
        <button cButton color="success" size="sm" (click)="addNewBlock(block?.blockID??0)">
          <svg [cIcon]="icons.cilPlus" name="cil-plus"></svg>
        </button>
      </div>
    </div>
  </c-card-footer>
</c-card>
<c-modal *ngIf="copyBlockFormVisible" id="emailCopyForm" [visible]="copyBlockFormVisible" (visibleChange)="handleCopyBlockChange($event)">
  <c-modal-header>
    <h5 cModalTitle>Copy Block </h5>
    <button (click)="toggleCopyFormVisible()" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>
    <app-block-copy-form [clients]="clients" [clientId]="clientId" [block]="block"
      (copySuccess)="copySuccess()"></app-block-copy-form>
  </c-modal-body>
  <c-modal-footer>
    <button (click)="toggleCopyFormVisible()" cButton color="secondary">
      Cancel
    </button>
    <button cButton color="primary" (click)="copyBlockToClient()">Copy</button>
  </c-modal-footer>
</c-modal>
<c-modal *ngIf="moveBlockFormVisible"  id="emailMoveForm" [visible]="moveBlockFormVisible" (visibleChange)="handleMoveBlockChange($event)">
  <c-modal-header>
    <h5 cModalTitle>Move Block </h5>
    <button (click)="toggleMoveFormVisible()" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>
    <app-block-move-form [clients]="clients" [clientId]="clientId" [block]="block"
    (moveSuccess)="copySuccess()"></app-block-move-form>
  </c-modal-body>
  <c-modal-footer>
    <button (click)="toggleMoveFormVisible()" cButton color="secondary">
      Cancel
    </button>
    <button cButton color="primary" (click)="moveBlockToClient()">Move</button>
  </c-modal-footer>
</c-modal>

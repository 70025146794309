import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { LoadService } from '../shared/services/load.service';

export const httpRequestInterceptor: HttpInterceptorFn = (req, next) => {
  const token = inject(AuthService).getLoginToken();
  const loadService = inject(LoadService);
  const modifiedReq = req.clone({
    headers: req.headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json')
  });
  // console.log(modifiedReq.headers.get('Authorization'));
  // return next(modifiedReq);
  return loadService.showLoaderUntilCompleted(next(modifiedReq));
};
